import React from "react";
import demo2 from "../../assets/images/ill_grow_money.jpg";
import ill_ring from "../../assets/images/ill_ring.png";
import ill_tools from "../../assets/images/tools.jpeg";
import ill_call from "../../assets/images/ill_call.jpg";
import ill_salesman from "../../assets/images/salesman.avif";
import sample_audio from "../../assets/female_sample.wav";
import sample_male from "../../assets/female_dec.wav";
import logo from "../../assets/images/logo.png";

import safaricom_logo from "../../assets/images/safaricom_logo.png";
import skiza_logo from "../../assets/images/skiza.png";

import nodemtech_logo from "../../assets/images/nodemtech_logo.png";

const Landing = () => {
  return (
    <div>
      <section id="content">
        <div class="bg-primary dk">
          <div class="text-center wrapper">
            <div class="m-t-xl m-b-xl">
              <div class="text-uc h1 font-bold inline">
                <div class="pull-left m-r-sm text-white">
                  Custom made<span class="font-thin text-muted"> SKiza</span>
                </div>
                <div
                  class="carousel slide carousel-fade inline auto aside text-left pull-left pull-none-xs"
                  data-interval="2000"
                >
                  <div class="carousel-inner">
                    <div class="item active text-warning">Tunes</div>
                  </div>
                </div>
              </div>
              <div class="h4 text-muted m-t-sm">
                Boost your business with a custom skiza tune to let your callers
                know about your business
              </div>
            </div>
            <p class="text-center m-b-xl">
              <a href="/wizard" class="btn btn-lg btn-dark m-sm">
                Get Started
              </a>
            </p>
          </div>
          <div class="padder">
            <div class="hbox">
              <aside class="col-md-3 v-bottom text-right">
                <div class="hidden-sm hidden-xs">
                  <section class="panel bg-dark inline m-b-n-lg m-r-n-lg aside-sm no-border device phone animated fadeInLeftBig">
                    <header class="panel-heading text-center">
                      <i class="fa fa-minus fa-2x m-b-n-xs block"></i>
                    </header>
                    <div>
                      <div class="m-l-xs m-r-xs">
                        <img src={ill_call} class="img-full" />
                      </div>
                    </div>
                  </section>
                </div>
              </aside>
              <aside class="col-sm-10 col-md-6">
                <section class="panel bg-dark m-b-n-lg no-border device animated fadeInUp">
                  <header class="panel-heading text-left">
                    <i class="fa fa-music fa-fw"></i>
                    <i class="fa fa-music fa-fw"></i>
                  </header>
                  <img src={demo2} class="img-full" />
                </section>
              </aside>
              <aside class="col-md-3 v-bottom">
                <div class="hidden-sm hidden-xs">
                  <section class="panel bg-light m-b-n-lg m-l-n-lg aside no-border device phone animated fadeInRightBig">
                    <header class="panel-heading text-center">
                      <i class="fa fa-minus fa-2x text-white m-b-n-xs block"></i>
                    </header>
                    <div class="">
                      <div class="m-l-xs m-r-xs">
                        <img
                          src={logo}
                          class="img-full"
                          style={{ padding: "35px" }}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </aside>
            </div>
          </div>
          <div class="dker pos-rlt">
            <div class="container wrapper">
              <div class="m-t-lg m-b-lg text-center">
                Create Professional Skiza Tunes for your business
              </div>
            </div>
          </div>
        </div>
        <div id="about">
          <div class="container">
            <div class="m-t-xl m-b-xl text-center wrapper">
              <h3>Reap Big with MySkiza.com</h3>
              <p class="text-muted">
                Get great benefits when you use our service
              </p>
            </div>
            <div class="row m-t-xl m-b-xl text-center">
              <div
                class="col-sm-4"
                data-ride="animated"
                data-animation="fadeInLeft"
                data-delay="300"
              >
                <p class="h3 m-b-lg">
                  <i class="fa fa-pencil fa-3x text-info"></i>
                </p>
                <div class="">
                  <h4 class="m-t-none">Script your own</h4>
                  <p class="text-muted m-t-lg">
                    You can script your own skiza tune and we will help you
                    create the perfect skiza tune for your business.
                  </p>
                </div>
              </div>
              <div
                class="col-sm-4"
                data-ride="animated"
                data-animation="fadeInLeft"
                data-delay="600"
              >
                <p class="h3 m-b-lg">
                  <i class="fa fa-microphone fa-3x text-info"></i>
                </p>
                <div class="">
                  <h4 class="m-t-none">Male/Female VoiceOver</h4>
                  <p class="text-muted m-t-lg">
                    We have a team of professional voice over artists that can
                    help you create the perfect ringback tone for your business.
                  </p>
                </div>
              </div>
              <div
                class="col-sm-4"
                data-ride="animated"
                data-animation="fadeInLeft"
                data-delay="900"
              >
                <p class="h3 m-b-lg">
                  <i class="fa fa-magic fa-3x text-info"></i>
                </p>
                <div class="">
                  <h4 class="m-t-none">AI Suggestions</h4>
                  <p class="text-muted m-t-lg">
                    We have a powerful AI that can suggest the best ringback
                    tones for your business based on the type of business you
                    do.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="responsive" class="bg-dark">
          <div class="text-center">
            <div class="container">
              <div class="m-t-xl m-b-xl wrapper">
                <h3 class="text-white">Our Partners</h3>
                <p>
                  Over 10,000+ businesses trust us with their custom skiza tunes
                </p>
              </div>
              <div class="row m-t-xl m-b-xl">
                <div
                  class="col-sm-4 wrapper-xl"
                  data-ride="animated"
                  data-animation="fadeInLeft"
                  data-delay="300"
                >
                  <p class="text-center h2 m-b-lg m-t-lg">
                    <span class="fa-stack fa-2x">
                      <img src={safaricom_logo} class="img-full" />
                    </span>
                  </p>
                </div>
                <div
                  class="col-sm-4 wrapper-xl"
                  data-ride="animated"
                  data-animation="fadeInLeft"
                  data-delay="600"
                >
                  <p class="text-center h1 m-b-lg">
                    <span class="fa-stack fa-2x">
                      <img src={skiza_logo} class="img-full" />
                    </span>
                  </p>
                </div>
                <div
                  class="col-sm-4 wrapper-xl"
                  data-ride="animated"
                  data-animation="fadeInLeft"
                  data-delay="900"
                >
                  <p class="text-center h2 m-b-lg m-t-lg">
                    <span class="fa-stack fa-2x">
                      <img src={nodemtech_logo} class="img-full" />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="newsletter" class="bg-white clearfix wrapper-lg">
          <div
            class="container text-center m-t-xl m-b-xl"
            data-ride="animated"
            data-animation="fadeIn"
          >
            <h2>Get Custom Skiza Tune</h2>
            <p>
              Get started, we can help you set up customised Skiza Tunes for
              your business.
            </p>

            <form
              class="form-inline m-t-xl m-b-xl"
              style={{ paddingRight: 20, paddingLeft: 20 }}
            >
              <section class="panel b-primary text-center">
                <ul class="list-group">
                  <li class="list-group-item text-center bg-light lt">
                    <div class="padder-v">
                      <span class="text-danger font-bold h1">KES 4,999</span>{" "}
                      only
                    </div>
                  </li>
                  <li class="list-group-item">
                    Professional Studio Quality recordings
                  </li>

                  <li class="list-group-item">
                    Get your Custom Skiza Tune in 48hrs
                  </li>
                  <li class="list-group-item">Pick Male/Female Voice</li>
                  <li class="list-group-item">Free AI powered scripting</li>
                </ul>
                <footer class="panel-footer">
                  <a href="/wizard" class="btn btn-primary m-t m-b">
                    Get Started
                  </a>
                </footer>
              </section>
            </form>
          </div>
        </div>
      </section>

      <footer id="footer">
        <div class="bg-primary text-center">
          <div class="container wrapper">
            <div class="m-t-xl m-b">
              Voice over Samples
              <div class="row m-t-xl m-b-xl">
              <div
                  class="col-sm-6 wrapper-xl"
                  data-ride="animated"
                  data-animation="fadeInLeft"
                  data-delay="300"
                >
                  <p><i className="fa fa-male" /> Male Voice</p>
                  <audio controls >
                    <source src={sample_male} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
              </div>
              <div
                  class="col-sm-6 wrapper-xl"
                  data-ride="animated"
                  data-animation="fadeInLeft"
                  data-delay="300"
                >
                  <p><i className="fa fa-female" /> FeMale Voice</p>
                  <audio controls >
                    <source src={sample_audio} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
              </div>
            
              </div>
            </div>
          </div>
          <i class="fa fa-caret-down fa-4x text-primary m-b-n-lg block"></i>
        </div>
        <div class="bg-dark dker wrapper">
          <div class="container text-center m-t-lg">
            <div class="row m-t-xl m-b-xl">
              <div
                class="col-sm-4"
                data-ride="animated"
                data-animation="fadeInLeft"
                data-delay="300"
              >
                <i class="fa fa-map-marker fa-3x icon-muted"></i>
                <h5 class="text-uc m-b m-t-lg">Find Us</h5>
                <p class="text-sm">
                  100 <br />
                  Westlands, Nairobi.
                </p>
              </div>
              <div
                class="col-sm-4"
                data-ride="animated"
                data-animation="fadeInUp"
                data-delay="600"
              >
                <i class="fa fa-envelope-o fa-3x icon-muted"></i>
                <h5 class="text-uc m-b m-t-lg">Mail Us</h5>
                <p class="text-sm">
                  Email us <br />
                  <a href="mailto:info@myskiza.com">info@myskiza.com</a>
                </p>
              </div>
              <div
                class="col-sm-4"
                data-ride="animated"
                data-animation="fadeInRight"
                data-delay="900"
              >
                <i class="fa fa-globe fa-3x icon-muted"></i>
                <h5 class="text-uc m-b m-t-lg">About US</h5>
                <p class="text-sm">
                  {" "}
                  About us
                  <br />
                  <a href="mailto:info@myskiza.com">Terms & conditions</a>
                  <br />
                  <a href="mailto:info@myskiza.com">Privacy Policy</a>
                </p>
              </div>
            </div>
            <div class="m-t-xl m-b-xl">
              <p>
                <a
                  href="#"
                  class="btn btn-icon btn-rounded btn-facebook bg-empty m-sm"
                >
                  <i class="fa fa-facebook"></i>
                </a>
                <a
                  href="#"
                  class="btn btn-icon btn-rounded btn-twitter bg-empty m-sm"
                >
                  <i class="fa fa-twitter"></i>
                </a>
                <a
                  href="#"
                  class="btn btn-icon btn-rounded btn-gplus bg-empty m-sm"
                >
                  <i class="fa fa-google-plus"></i>
                </a>
              </p>
              <p>
                <a
                  href="#content"
                  data-jump="true"
                  class="btn btn-icon btn-rounded btn-dark b-dark bg-empty m-sm text-muted"
                >
                  <i class="fa fa-angle-up"></i>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Landing;
