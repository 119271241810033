import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, query, where, getDocs,orderBy } from "firebase/firestore";
import { auth } from "../../configs/firebase.config";
import { firestore } from "../../configs/firebase.config";
import { LoaderIcon } from "react-hot-toast";


const Dashboard = () => {
  const navigate = useNavigate();
  const [userRecords, setUserRecords] = useState([]);
  const [loader, showLoader] = useState(true);
  const [stats,saveStats] = useState({});
  var countPending = 0,countCompleted = 0 ,inStudio = 0;

  const getUserData = async () => {
    const q = query(
      collection(firestore, "biztunes"),
      where("uid", "==", auth.currentUser.uid)
    );

    var records = [];
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      console.log(`${doc.id} => ${doc.get("status")}`);
      const record = {
        id: doc.id,
        name: doc.get("name"),
        date: doc.get("date"),
        script: doc.get("script"),
        status: doc.get("status"),
        file_url: doc.get("file_url"),
      };

      if(record.status === "Pending Payment")
        countPending++;

      if(record.status === "Completed")
        countCompleted++;

      if(record.status === "Processing")
        inStudio++;

      records.push(record);
    });

    //order records by date
    records.sort((a, b) => b.date - a.date);
    setUserRecords(records);
    saveStats({pending: countPending,completed: countCompleted,inStudio: inStudio});
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <section id="content" style={{padding:5,backgroundColor:'white'}}>
      <h3 style={{padding:5}}>My Skiza Tune Orders</h3>
      <p style={{padding:10,fontSize:14}}>View history of Skiza tune Orders you have made so far</p>

    <div className="row m-l-none m-r-none bg-light lter" >
          <div className='col-sm-6 col-md-3 padder-v b-r b-light card' style={{margin: '10px', paddingBottom: '5px'}}>
              <div className='h4 block m-t-xs'>{stats.pending}</div>
              <div className='text-muted text-uc'>Pending Payment</div>
          </div>
          <div className='col-sm-6 col-md-3 padder-v b-r b-light card' style={{margin: '10px', paddingBottom: '5px'}}>
              <div className='h4 block m-t-xs'>{stats.inStudio}</div>
              <div className='text-muted text-uc'>In-Studio</div>
          </div>
          <div className='col-sm-6 col-md-3 padder-v b-r b-light card' style={{margin: '10px', paddingBottom: '5px'}}>
              <div className='h4 block m-t-xs'>{stats.completed}</div>
              <div className='text-muted text-uc'>Completed</div>
          </div>
      </div>
      <section className="vbox">
        <section className="scrollable">
          <section class="panel panel-default">
            <header class="panel-heading">My Skiza Tune Orders</header>

            {userRecords.length === 0 ? (
              <div className="text-center" style={{ padding: "20px" }}>
                {loader ? (
                  <>
                     <p> Fetching your orders <LoaderIcon /></p>
                  </>
                ) : (
                  <p>You have not created any skiza tune yet.</p>
                )}
              </div>
            ) : (
              <table class="table table-striped m-b-none" style={{fontSize:12}}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Script</th>
                    <th>Status</th>
                    <th>Skiza Tune</th>
                  </tr>
                </thead>
                <tbody>
                  {userRecords.map((record, index) => (
                    <tr>
                      <td>{++index}</td>
                      <td>
                        {record.date
                          .toDate()
                          .toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "numeric",
                            year: "numeric",
                          })}
                      </td>
                      <td><p >{record.script}</p></td>
                      <td>{record.status}</td>
                       <td>{record.file_url ? (<audio controls><source src={record.file_url} type="audio/mpeg" /></audio>) : <p>-</p>} </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </section>
          <button
            className="btn bg-theme text-white"
            onClick={() => navigate("/wizard")}
          >
            Create New
          </button>
        </section>
      </section>
      <a
        href="#"
        className="hide nav-off-screen-block"
        data-toggle="class:nav-off-screen"
        data-target="#nav"
      ></a>
    </section>
  );
};

export default Dashboard;
