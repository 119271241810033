import React, { useEffect,useState} from 'react';
import { useNavigate } from "react-router-dom";
import '../../styles/adminDashboard.css';
import { collection, query, where,getDocs } from "firebase/firestore"; 
import  { auth } from "../../configs/firebase.config";
import { firestore } from "../../configs/firebase.config";
import Forbidden from '../pages/Forbidden';

const AdminDashboard = () => {
    const navigate = useNavigate();
    const gotoRequestActions = (userRecord) => {
      navigate("/requestActions", { state: { record: userRecord } });
        // navigate("/requestActions");
    };
    const [userRecords, setUserRecords] = useState([]);
    const [stats,saveStats] = useState({});

    var countPending = 0,countInStudio =0,countCompleted = 0;
    const getData = async () => { 
      const q = query(collection(firestore,"biztunes"));
  
      var records = [];
      const querySnapshot = await getDocs(q);
      

      querySnapshot.forEach((doc) => {
        console.log(`${doc.id} => ${doc.get('status')}`);

        const record = {
          id: doc.id,
          name: doc.get('name'),
          date: doc.get('date'),
          script: doc.get('script'),
          status: doc.get('status'),
          status_reason: doc.get('status_reason'),
          file_url: doc.get('file_url'),
        }

        if(record.status === 'Pending Payment')
          countPending++;

        if(record.status === 'Processing')
          countInStudio++;

        if(record.status === 'Completed')
          countCompleted++;
  
        records.push(record);
  
      });
    
      //order records by date
      records.sort((a, b) => b.date - a.date);
  
      setUserRecords(records);
      saveStats({new : countPending,inStudio:countInStudio,inStudio: countInStudio, completed: countCompleted});
    }
  
    useEffect(() => {
      getData(); 
    }, []);

    if(auth.currentUser.email !== 'admin@myskiza.com'){
        return <Forbidden />
    }else
    return (
        <section  style={{marginTop:100}}>
            <div className="row m-l-none m-r-none bg-light lter" style={{margin: 'auto'}}>
                <div className='col-sm-6 col-md-3 padder-v b-r b-light card' style={{margin: '10px', paddingBottom: '5px'}}>
                    <div className='h3 block m-t-xs'>{stats.new}</div>
                    <div className='text-muted text-uc'>New Orders</div>
                </div>
                <div className='col-sm-6 col-md-3 padder-v b-r b-light card' style={{margin: '10px', paddingBottom: '5px'}}>
                    <div className='h3 block m-t-xs'>{stats.inStudio}</div>
                    <div className='text-muted text-uc'>In-Studio</div>
                </div>
                <div className='col-sm-6 col-md-3 padder-v b-r b-light card' style={{margin: '10px', paddingBottom: '5px'}}>
                    <div className='h3 block m-t-xs'>{stats.completed}</div>
                    <div className='text-muted text-uc'>Completed Orders</div>
                </div>
            </div>
            <section className="panel panel-default">
                    <header className="panel-heading" style={{backgroundColor: '#fff'}}>Stats</header>
                   <div className='row wrapper' style={{backgroundColor: '#f9f9f9', borderBottom: '2px solid #ddd'}}>
                     <div className="input-group" style={{float: 'right', width: '20%', margin: '10px', right: '10px'}}>
                       <input type="text" className="input-sm form-control" placeholder="Search" style={{borderRadius: '0'}} />
                        <span className="input-group-btn">
                         <button className="btn btn-group-xs btn-default" type="button" style={{borderRadius: '0px', height: '30px', fontSize: '13px'}}>Go!</button>
                        </span>
                      </div>
                    </div>
                    {userRecords.length === 0 ? (
                      <div>
                        There are no records to display.
                      </div>
                    ) : (
                      <table className="table table-striped m-b-none" style={{fontSize:12}}>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Name</th>
                          <th>Script</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                      {userRecords.map((record, index) => ( 
                          <tr>
                          <td>{record.date.toDate().toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false })}
                          </td>
                          <td>{record.name}</td>
                          <td>{record.script}</td>
                          <td>
                            <button className="btn btn-xs btn-success" onClick={()=>gotoRequestActions(record)}>View</button>
                          </td>
                        </tr>
                      ))}                    
                      </tbody>
                    </table>
                    )}
                    <footer className="panel-footer">
                  <div className="row">
                    <div className="col-sm-4 text-center">
                      <small className="text-muted inline m-t-sm m-b-sm">showing 20-30 of 50 items</small>
                    </div>
                    <div className="col-sm-4 text-right text-center-xs">                
                      <ul className="pagination pagination-sm m-t-none m-b-none">
                        <li><a href="#"><i className="fa fa-chevron-left"></i></a></li>
                        <li><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li><a href="#"><i className="fa fa-chevron-right"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </footer>
                  </section>
        </section>
        
    );
};
export default AdminDashboard;