import { useSearchParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { auth, firestore } from "../../configs/firebase.config";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function VerifyPayment() {
  //update payment status in db

  const [searchParams] = useSearchParams();

  // Access the query parameters
  const status = searchParams.get("status");
  const txncd = searchParams.get("txncd");
  const order_id = searchParams.get("p1");
  const phone = searchParams.get("p2");

  const updateFirebase = async () => {
   
      //update status on firebase
      //get doc id
      console.log("Updating firebase OID is " + order_id);
      const q = query(
        collection(firestore, "biztunes"),
        where("oid", "==", order_id)
      );

      const querySnapshot = await getDocs(q);
      var docId = "";
      querySnapshot.forEach((doc) => {
        docId = doc.id;
        console.log("Found " + docId);
      });

      console.log("Doc ID is " + docId);

      if (docId !== "") {
        const docRef = doc(firestore, "biztunes", docId);
        
        await updateDoc(docRef, {
          phone: phone,
          txncd: txncd,
          status: status === "aei7p7yrx4ae34" ? "Processing" : "Payment Failed!", 
        });

        if(status === "aei7p7yrx4ae34"){
          //"Send Confirmation Email & SMS to user & admin");
          console.log("Sending confirmation email & SMS");

          const emailMessage = "Your custom Skiza order has been confirmed. Order ID: " + order_id + " Transaction code: " + txncd + " You Order has been successfully placed , We will notify you when your Skiza Tune is ready within the next 72 hours.";
          //post api call
          const emailData = {
            email_to: auth.currentUser.email+",admin@myskiza.com",
            email_from: 'Custom Skiza <admin@myskiza.com>',
            message: emailMessage,
            subject: 'Custom Skiza Order Confirmation',
          };

            fetch('https://api.nodemtech.com/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
            mode: 'no-cors', 
            }).then((response) => {
            console.log(response);
            });

          //send SMS
          const smsMessage = "Custom Skiza Order Confirmed. Order ID: " + order_id + " Transaction code: " + txncd + ".We will notify you when your Skiza Tune is ready within the next 72 hrs.";
         
          //format phone remove first character 0 and add 254
          phone = "254" + phone.substring(1);

          fetch(`https://platform.clickatell.com/messages/http/send?apiKey=1NMmvrV3RqmZvw4uXrO-5Q==&to=${phone}&content=${smsMessage}"`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            mode: 'no-cors', 
          }).then((response) => {
            console.log(response);
          });

        }
      }
    
  };

  useEffect(() => {
    updateFirebase();
  }, []);

  return (
    <div className="text-center mt-20vh" style={{ margin: 20 }}>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <div className="card" style={{ backgroundColor: "white", padding: 10 }}>
          {status === "aei7p7yrx4ae34" ? (
            <>
              <h1>Your order has been confirmed.</h1>
              <p>
                <strong>Order ID:</strong> {order_id}
              </p>
              <p>
                <strong>Transaction code:</strong> {txncd}
              </p>
              <p>
                You Order has been successfully placed , you will be notified
                once recording is ready.
              </p>
              <Link to="/dashboard">
                <button  className="btn btn-sm bg-theme text-white">
                    View all orders
                </button>
              </Link>
            </>
          ) : (
            <>
              <h1><i style={{color:'orange'}} className="fa fa-warning" /> Payment Failed!</h1>
              <p>
                <strong>Order ID:</strong> {order_id}
              </p>
              <p>
                We could not process your payment. Please try again in 5 minutes
              </p>
              <Link to="/dashboard">
                <button  className="btn btn-sm bg-theme text-white">
                    View all orders
                </button>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default VerifyPayment;
