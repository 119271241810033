import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoaderIcon } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import lipanampesa from "../../assets/images/lipanampesa.png";
import toast, { Toaster } from "react-hot-toast";
import app, { auth } from "../../configs/firebase.config";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  collection,
  doc,
  setDoc,
  query,
  Timestamp,
  getDoc,
} from "firebase/firestore";
import CryptoJS from "crypto-js";
import OpenAI from "openai";
import audio_female from "../../assets/female_sample.wav";
import audio_male from "../../assets/female_dec.wav";

const AIEditor = (props) => {
  const openai = new OpenAI({
    apiKey: "sk-proj-1KIyjb2qUhwuwjEa1zz2T3BlbkFJh6nqFebz2gxl0EutgCXZ",
    dangerouslyAllowBrowser: true,
  });

  const navigate = useNavigate();

  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [phone, setPhone] = useState("");
  const [ipayData, setIpayData] = useState({});
  const [ipayHash, setIpayHash] = useState("");
  const [voicePrefDialog, showVoicePrefDialog] = useState(false);
  const [prefferedVoice, setPrefferedVoice] = useState("female");

  const [ipayDialog, showIPayDialog] = useState(false); // State variable for modal visibility
  const [paymentDialog, showPaymentDialog] = useState(false); // State variable for modal visibility

  const location = useLocation();
  const firebaseAuth = getAuth(app);
  const firestore = getFirestore(app, "biztunes");

  const saveToFirebase = async (orderId) => {
    //collection id : biztunes
    //document id : user id
    //document data : fieldsToSave
    const fieldsToSave = {
      //get firebase auth user name
      oid: "" + orderId,
      uid: firebaseAuth.currentUser.uid,
      date: Timestamp.now(),
      name: firebaseAuth.currentUser.displayName,
      script: selectedSuggestion,
      file_url: "",
      voice: prefferedVoice,
      status: "Pending Payment",
    };

    //save new document to firebase
    await setDoc(doc(collection(firestore, "biztunes")), fieldsToSave);
  };

  //timestamp in millis as order id
  const orderId = new Date().getTime();

  const handlePayment = () => {
    const fields = {
      live: "1",
      oid: orderId.toString(),
      inv: orderId.toString(),
      ttl: "1",
      tel: phone,
      eml: firebaseAuth.currentUser.email,
      vid: "nodemtech",
      curr: "KES",
      p1: orderId.toString(),
      p2: phone,
      p3: "",
      p4: "",
      cbk: "https://myskiza.com/verifyPayment",
      cst: "1",
      crl: "2",
    };

    const datastring =
      fields.live +
      fields.oid +
      fields.inv +
      fields.ttl +
      fields.tel +
      fields.eml +
      fields.vid +
      fields.curr +
      fields.p1 +
      fields.p2 +
      fields.p3 +
      fields.p4 +
      fields.cbk +
      fields.cst +
      fields.crl;

    const hashkey = "3H48Q%uW7S#aUwBfTCjZB7T3vU@JRvWc"; // Use "demoCHANGED" for testing where vid is set to "demo"

    // Generating the HashString sample
    const generated_hash = CryptoJS.HmacSHA1(datastring, hashkey).toString();

    setIpayData(fields);
    setIpayHash(generated_hash);
    saveToFirebase(orderId);
  };

  const generate = async (query) => {
    setShowLoader(true);
    if (query === "") {
      console.log("Empty AI Query:");
      return;
    }
    //console.log("Generate with Query:", query);
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [{ role: "user", content: query }],
      stream: false,
    });

    console.log("AI response", response);
    var res = [];
    for (const suggestion of JSON.parse(
      response.choices[0]?.message?.content
    )) {
      res.push(suggestion || "");
    }
    setSuggestions(res);
    setShowLoader(false);
  };

  const getBusinessInfo = async () => {
    const ref = doc(firestore, "bizinfo", firebaseAuth.currentUser.uid);
    const querySnapshot = await getDoc(ref);

    if (querySnapshot.data() !== null) {
      const record = {
        // id: querySnapshot.id,
        name: querySnapshot.data().name,
        location: querySnapshot.data().location,
        description: querySnapshot.data().description,
        industry: querySnapshot.data().industry,
      };

      //setBizInfo(record);

      //generate AI suggestions
      const aiQuery =
        "Generate 4 , 80 word , business slogans for a business named:" +
        record.name +
        " located at " +
        record.location +
        " in the " +
        record.industry +
        " industry, based on this description of their services: " +
        record.description +
        ".These should be suitable for a 30 sec voice over ad.Return response as a json array of strings.";

      generate(aiQuery);
    }
  };

  const [wordCount, setWordCount] = useState(0);

  function countWords(text) {
    return text.split(/\s+/).length;
  }

  useEffect(() => {
    const btnSubmit = document.getElementById("btnSubmit"); // Replace with your button's ID
    if (ipayHash !== "") btnSubmit.click();

    getBusinessInfo();
  }, [ipayHash]);

  return (
    <div className="text-center" style={{ fontSize: 14 }}>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <Toaster />
        {showLoader && (
          <div>
            <p>Generating AI-powered suggestions for your business...</p>{" "}
            <p>Please wait...</p>{" "}
            <LoaderIcon
              style={{
                height: "30px",
                width: "30px",
                marginBottom: "10px",
                marginLeft: "45%",
              }}
            />
          </div>
        )}

        <div style={{ padding: "10px" }}>
          {suggestions.length > 0 && (
            <>
              <section id="content">
                <section class="vbox">
                  <section class="scrollable wrapper">
                    <div class="row">
                      <p style={{ padding: "10px" }}>
                        Get a quick start with our AI powered suggestions.
                      </p>

                      <table
                        className="table table-striped m-b-none"
                        style={{ fontSize: 12 }}
                      >
                        <thead>
                          <tr>
                            <th>Suggestion</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {suggestions.map((suggestion, index) => (
                            <tr>
                              <td>
                                <p>{suggestion}</p>
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm bg-theme text-white"
                                  style={{ alignSelf: "center" }}
                                  onClick={() => {
                                    //navigate to component in this page
                                    document
                                      .getElementById("editor")
                                      .scrollIntoView();
                                    setWordCount(countWords(suggestion));
                                    setSelectedSuggestion(suggestion);
                                  }}
                                >
                                  Select
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </section>
              </section>
            </>
          )}

          <div>
            <br />
            <label>Script your Skiza Tune message here: (Max 80 words)</label>
            <p>
              Word count: <span id="wordCount">{wordCount}</span>
            </p>

            <textarea
              oninput="limitWords(this)"
              id="editor"
              value={selectedSuggestion}
              onChange={(e) => {
                setSelectedSuggestion(e.target.value);
                setWordCount(countWords(e.target.value));
              }}
              rows="4"
              cols="13"
              className="paper"
              style={{
                color: "black",
                height: "150px",
                padding: "10px",
                margin: "10px",
                width: "100%",
                border: "1px dashed grey",
                borderRadius: "8px",
                fontSize: "18px",
              }}
            />

            <button
              className="btn bg-theme text-white"
              onClick={() => {
                if (selectedSuggestion === "") {
                  toast.error("Please enter your Skiza message!");
                } else {
                  showVoicePrefDialog(true);
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      {paymentDialog && (
        <div className="modal">
          <div className="modal-content" style={{ width: "80%" }}>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img
                src={lipanampesa}
                alt="Lipa na M-PESA"
                style={{ width: "60%", padding: "5px" }}
              />
              <p style={{ color: "grey", padding: "5px" }}>
                Please enter your M-PESA phone number to complete payment.
              </p>
            </div>
            <hr />
            <div className="modal-body" style={{ padding: 0 }}>
              <input
                className="form-control mt-3"
                type="phone"
                placeholder="0712 XXXX 24"
                value={phone}
                maxLength={10}
                pattern="[0-9]*"
                keyboardType="numeric"
                onChange={(e) => setPhone(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
            <div className="modal-footer">
              <form action="https://payments.ipayafrica.com/v3/ke">
                {Object.keys(ipayData).map((key) => {
                  return (
                    <input name={key} type="hidden" value={ipayData[key]} />
                  );
                })}
                <input name="hsh" type="hidden" value={ipayHash} />
                <button
                  id="btnSubmit"
                  type="submit"
                  style={{ display: "none" }}
                ></button>
              </form>
              <button
                className="btn btn-small btn-danger text-white"
                onClick={() => showPaymentDialog(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-small bg-theme text-white"
                onClick={() => {
                  if (phone === "") {
                    toast.error(
                      "Please enter your phone number to complete payment"
                    );
                  } if (auth.currentUser == null) {
                    toast.error("Please login to complete payment");
                    navigate("/login", {
                      state: {
                        from: location,
                        data: {
                          phone: phone,
                          script: selectedSuggestion,
                          voice: prefferedVoice,
                        },
                      },
                    });
                  } else handlePayment();
                }}
              >
                Complete Payment
              </button>
            </div>
          </div>
        </div>
      )}

      {voicePrefDialog && (
        <div className="modal">
          <div className="modal-content" style={{ width: "80%" }}>
            <div className="container wrapper" style={{ fontSize: 14 }}>
              <div className="m-t-xl m-b">
                <h4 style={{ paddingLeft: 15 }}>Listen to Samples</h4>
                <div className="row m-t-xl m-b-xl" style={{ marginLeft: 0 }}>
                  <div
                    className="col-sm-4 wrapper-xl"
                    data-ride="animated"
                    data-animation="fadeInLeft"
                    data-delay="300"
                  >
                    <p>
                      <i className="fa fa-male" /> Male Voice Sample
                    </p>
                    <audio style={{ width: "100%" }} controls>
                      <source src={audio_male} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                  <div
                    className="col-sm-4 wrapper-xl"
                    data-ride="animated"
                    data-animation="fadeInLeft"
                    data-delay="300"
                  >
                    <p>
                      <i className="fa fa-female" /> FeMale Voice Sample
                    </p>
                    <audio controls style={{ width: "100%" }}>
                      <source src={audio_female} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                </div>

                <p>Please Choose Preferred Voice</p>
                <select
                  className="form-control mt-3"
                  value={prefferedVoice}
                  onChange={(e) => setPrefferedVoice(e.target.value)}
                >
                  <option value="Female">Female Voice</option>
                  <option value="Male">Male Voice</option>
                </select>
              </div>
              <button
              className="btn bg-theme text-white"
              onClick={() => {
                showVoicePrefDialog(false);
                showPaymentDialog(true);
              }}
            >
              Next
            </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AIEditor;
