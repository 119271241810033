import { signOut } from "firebase/auth";
import React, { useContext } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { auth } from "../configs/firebase.config";
import { AuthContext } from "../Contexts/AuthProvider";
import logo from "../assets/images/logo.png";


const Nav = () => {
  const { user, signOutUser } = useContext(AuthContext);

  const handleSignOut = () => {
    signOutUser()
      .then(() => {
        toast.success("User Logout Successfull");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };


  return   (
  <header id="header" className="navbar navbar-fixed-top bg-white box-shadow b-b b-light"  data-spy="affix" data-offset-top="1">
  <div className="container">
    <div className="navbar-header">        
      <a href="/" className="navbar-brand"><img src={logo} className="m-r-sm" /><span className="text-muted">MySkiza.com</span></a>
      <button className="btn btn-link visible-xs" type="button" data-toggle="collapse" data-target=".navbar-collapse">
        <i className="fa fa-bars"></i>
      </button>
    </div>
    <div className="collapse navbar-collapse">
      <ul className="nav navbar-nav navbar-right">
        <li className="active">
          <a href="/">Home</a>
        </li>
        <li>
          {user && user.email !== 'admin@myskiza.com' ? ( <a href="/dashboard">Dashboard</a>) : user && ( <a href="/admin">Admin Dashboard</a>)}
        </li>
        <li>
          { !user ? (
          <div className="m-t-sm"><a href="/login
          " className="btn btn-link btn-sm">Sign in</a> <a href="/signup" className="btn btn-sm btn-success m-l"><strong>Sign up</strong></a>
          </div>
          ): (<div className="m-t-sm">
            <p className="btn btn-link btn-sm">{user?.displayName}</p>
            <button onClick={handleSignOut} className="btn btn-link btn-sm btn-danger">Sign out </button></div>)
          }
        </li>
      </ul>
    </div>
  </div>
</header>);
};

export default Nav;
