import React from "react";
import { Outlet } from "react-router-dom/dist";
import Nav from "./Nav";

const Main = () => {
  return (
    <>
      <div>
        <Nav />
      </div>
      <div style={{ marginTop: "50px" }}>
        <Outlet />
      </div>
    </>
  );
};

export default Main;
