import React, { useState, useEffect, useContext, useCallback } from "react";
import { LoaderIcon } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import lipanampesa from "../../assets/images/lipanampesa.png";
import toast, { Toaster } from "react-hot-toast";
import app from "../../configs/firebase.config";
import { firestore, auth } from "../../configs/firebase.config";
import { AuthContext } from "../../Contexts/AuthProvider";

import {
  collection,
  doc,
  setDoc,
  query,
  getFirestore,
  getDoc,
} from "firebase/firestore";
import AIEditor from "./AIEditor";



const Wizard = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const data = location.state?.data || "";

  useEffect(() => {
    // getBusinessInfo();
    console.log("Parent useEffect got data = ",data);
  }, []);

  const BusinessInfoForm = () => {
    const [businessName, setBusinessName] = useState("");
    const [businessLocation, setBusinessLocation] = useState("");
    const [businessType, setBusinessType] = useState("");
    const [businessDescription, setBusinessDescription] = useState("");
    const [bizInfo, setBizInfo] = useState({});

    console.log("CURRENT UID", user?.uid);

    const saveBusinessInfo = async () => {
      //document data : fieldsToSave
      const fieldsToSave = {
        //get firebase auth user name
        name: businessName,
        location: businessLocation,
        industry: businessType,
        description: businessDescription,
      };

      //save new document to firebase
      await setDoc(doc(firestore, "bizinfo", user?.uid), fieldsToSave);
    };

    const getBusinessInfo = async () => {
      console.log("Getting Business Info", user?.uid);
      const ref = doc(firestore, "bizinfo", user?.uid);
      const querySnapshot = await getDoc(ref);

      console.log("Auth", auth);
      console.log("BizInfo", querySnapshot.data().name);

      if (querySnapshot.data() !== null) {
        const record = {
          // id: querySnapshot.id,
          name: querySnapshot.data().name,
          location: querySnapshot.data().location,
          description: querySnapshot.data().description,
          industry: querySnapshot.data().industry,
        };

        setBizInfo(record);
        //setBusinessName(record.name);
      }
    };

    useEffect(() => {
      getBusinessInfo();
      console.log("Did call biz info ");
    }, []);

    return (
      <form>
        <div className="step-pane active" id="step1" style={{fontSize:14}}>
          <div>
            <div>
              <div className="d-flex justify-content-center align-items-center flex-column">
                <h4>Tell us about your business</h4>
                <p > 
                  Tell us about your business and get AI-powered suggestions to
                  help you script your next Skiza Tune.
                </p>
              </div>
              <div>
                <br />
                <label
                  className="mt-5"
                  style={{ display: "flex", paddingBottom: 10 }}
                >
                  Business Name
                </label>
                <input
                  type="text"
                  className="form-control mt-3"
                  placeholder={bizInfo? bizInfo.name : "e.g Modern Fitness Gym"}
                  onChange={useCallback((e) => {
                    console.log("UseCallback set name ok");
                    setBusinessName(e.target.value);
                  }, [])}
                />
                <br />
                <label
                  className="mt-3"
                  style={{ display: "flex", paddingBottom: 10 }}
                >
                  Your website/Business Location
                </label>
                <input
                  type="text"
                  className="form-control mt-3"
                  placeholder={bizInfo? bizInfo.location :"e.g Westlands"}
                  onChange={(e) => setBusinessLocation(e.target.value)}
                />
                <br />
                <label
                  className="mt-3"
                  style={{ display: "flex", paddingBottom: 10 }}
                >
                  Type of Business/Industry
                </label>
                <select
                  className="form-control mt-3"
                  onChange={(e) => setBusinessType(e.target.value)}
                >
                  <option value="1">Select</option>
                  <option value="E-Commerce">E-Commerce</option>
                  <option value="Fitness and Wellness">
                    Fitness and Wellness
                  </option>
                  <option value="Information Technology Services">
                    Information Technology Services
                  </option>
                  <option value="Financial Services">Financial Services</option>
                  <option value="Food & Beverage">Food & Beverage</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Retail">Retail</option>
                  <option value="Transportation">Transportation</option>
                  <option value="Other">Other</option>
                </select>
                <br />
                <label
                  className="mt-3"
                  style={{ display: "flex", paddingBottom: 10 }}
                >
                  Products/Services you offer
                </label>
                <textarea
                  className="form-control mt-3"
                  placeholder={bizInfo? bizInfo.description :"e.g Modern Fitness Gym"}
                  onChange={(e) => setBusinessDescription(e.target.value)}
                />
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="actions m-t">
          {step < 3 && (
            <button
              type="button"
              className="btn btn-default btn-sm btn-next"
              data-target="#form-wizard"
              style={{ marginLeft: 5 }}
              data-wizard="next"
              data-last="Finish"
              onClick={() => {
                saveBusinessInfo();
                setCurrentStep(step + 1);
              }}
            >
              Next
            </button>
          )}
        </div>
      </form>
    );
  };


  const steps = ["Business Information", "Skiza Message","Proceed to Checkout"];

  const [step, setCurrentStep] = useState(1);
  // const aiQuery =
  // "Generate 4 , 80 word , business slogans for " +
  // bizInfo.businessName +
  // " located at " +
  // bizInfo.businessLocation +
  // " in the " +
  // bizInfo.businessType +
  // " industry, based on this description: " +
  // bizInfo.businessDescription +
  // ".These should be suitable for a 30 sec voice over ad.Return response as a json array of strings.";
  
  const wizardSwitcher = (step) => {
    console.log("Render Step", step);
    switch (step) {
      case 1:
        return <BusinessInfoForm />;
      case 2:
        return <AIEditor />;
      case 3:
          return <></>;
      default:
        return <></>;
    }
  };

  return (
    <section className="vbox" style={{ marginTop: 90 }}>
      <section>
        <section className="hbox stretch">
          <section id="content">
            <section className="vbox">
              <section className="scrollable">
                <div className="m-b-md">
                  <h3 className="m-b-none" style={{paddingLeft:10}}>
                    <strong>Create Custom Skiza Tune</strong>
                  </h3>
                </div>
                <div className="panel panel-default">
                  <div className="wizard clearfix" id="form-wizard">
                    <ul className="steps">
                      {steps.map((stepMessage, index) =>
                        step === index + 1 ? (
                          <li data-target={"#step" + index} className="active">
                            <span className="badge badge-info">
                              {index + 1}
                            </span>
                            {stepMessage}
                          </li>
                        ) : (
                          <li data-target={"#step" + index}>
                            <span className="badge badge-info">
                              {index + 1}
                            </span>
                            {stepMessage}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div className="step-content">
                    {wizardSwitcher(step)}
                    <div className="actions m-t">
                      {step > 1 && (
                        <button
                          type="button"
                          className="btn btn-default btn-sm btn-prev"
                          data-target="#form-wizard"
                          data-wizard="previous"
                          onClick={() => setCurrentStep(step - 1)}
                        >
                          Prev
                        </button>
                      )}

                      {step < 3 && step !== 1 && (
                        <button
                          type="button"
                          className="btn btn-default btn-sm btn-next"
                          data-target="#form-wizard"
                          style={{ marginLeft: 5 }}
                          data-wizard="next"
                          data-last="Finish"
                          onClick={() => {
                            setCurrentStep(step + 1);
                          }}
                        >
                          Next
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </section>
            <a
              href="#"
              className="hide nav-off-screen-block"
              data-toggle="class:nav-off-screen"
              data-target="#nav"
            ></a>
          </section>
          <aside className="bg-light lter b-l aside-md hide" id="notes">
            <div className="wrapper">Notification</div>
          </aside>
        </section>
      </section>
    </section>
  );
};

export default Wizard;
