import React, { useRef } from "react";
import "./requestActions.css";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../configs/firebase.config";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const RequestActions = () => {
  const location = useLocation();
  const record = location.state.record;
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("pending");
  const [rejectInput, setRejectInput] = useState("");
  const [fileUrl, setFileUrl] = useState(record.file_url);

  const handleApprove = async (id) => {
    const docRef = doc(firestore, "biztunes", id);
    await updateDoc(docRef, {
      status: "approved",
    });
    setUpdateStatus("approved");
    setShowApproveModal(false);
  };
  const handleshowApproveModal = () => {
    setShowApproveModal(true);
  };
  const handleshowRejectModal = () => {
    setShowRejectModal(true);
  };
  const fileInputRef = useRef(null);

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    //save file to firebase storage

    const storage = getStorage();
    const storageRef = ref(storage, `skiza_tunes/${record.id}_tune.mp3`);
    // const gsref = ref(storage, `gs://bucket/skiza_tunes/${record.id}_tune.mp3`);
    uploadBytes(storageRef, file)
      .then((snapshot) => {
        //get uploaded file url
        getDownloadURL(storageRef).then((url) => {
          const docRef = doc(firestore, "biztunes", record.id);
          updateDoc(docRef, {
            file_url: url,
            status: "completed",
          });
          setUpdateStatus("completed");
          setFileUrl(url);
          console.log("File available at", url);
        });
        console.log("Uploaded a blob or file!", snapshot);
      })
      .catch((error) => {
        console.error("Error uploading file", error);
      });
  };

  const handleRejectChange = (e) => {
    setRejectInput(e.target.value);
  };

  const handleRejectSubmit = async () => {
    try {
      const docRef = doc(firestore, "biztunes", record.id);
      await updateDoc(docRef, {
        status_reason: rejectInput,
      });
      setRejectInput("");
      setUpdateStatus("rejected");
      setShowRejectModal(false);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <>
      <div className="text-center mt-20vh" style={{ margin: 90 }}>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div
            className="card"
            style={{ padding: "20px", backgroundColor: "white" }}
          >
            <h2>
              <strong>Manage Order</strong>
            </h2>
            { !fileUrl && (  <div className="text-center" style={{ padding: "20px" }}>
              Please review and either
              <button
                className="btn btn-xs btn-success"
                style={{ marginRight: "10px", marginLeft: "10px" }}
                onClick={() => {
                  handleshowApproveModal();
                }}
              >
                Approve
              </button>
              or
              <button
                className="btn btn-xs btn-danger"
                style={{ marginRight: "10px", marginLeft: "10px" }}
                onClick={handleshowRejectModal}
              >
                Reject
              </button>
              this order
            </div>)}
            <table
              class="table table-striped m-b-none"
              style={{ textAlign: "left" }}
            >
              <tbody>
                <tr>
                  <td>
                    <strong>Customer Name</strong>
                  </td>
                  <td>{record.name}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Order ID</strong>
                  </td>
                  <td>{record.id}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Date</strong>
                  </td>
                  <td>
                    {record.date
                      .toDate()
                      .toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Script</strong>
                  </td>
                  <td>{record.script}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Status</strong>
                  </td>
                  <td>{updateStatus}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Recorded Tone</strong>
                  </td>
                  <td>
                    {fileUrl ? (
                      <audio
                        controls
                        class="btn btn-sm btn-warning b-white bg-empty"
                      >
                        <source src={fileUrl} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    ) : (
                      <p>No tune uploaded</p>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            {!fileUrl && (
              <div>
                <div>
                  <p style={{ marginTop: "30px" }}>
                    Please upload skiza tune to complete this order
                  </p>
                  <button
                    className="btn btn-s btn-info"
                    onClick={handleUploadButtonClick}
                    style={{ marginTop: "10px" }}
                  >
                    Upload skiza tune
                  </button>
                </div>
                <input
                  style={{ display: "none" }}
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="audio/mpeg"
                />
              </div>
            )}
            <section>
              <div className="buttonContainer">
                {showRejectModal && (
                  <div className="modal">
                    <div class="modal-header">
                      <span class="close">&times;</span>
                    </div>
                    <div class="modal-content">
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <h2>
                          <strong>Please State your Reason:</strong>
                        </h2>
                        <p style={{ color: "grey", padding: "10px" }}>
                          Kindly submit your reason for rejecting this request.
                        </p>
                      </div>
                      <hr />
                      <div class="modal-body">
                        <input
                          type="text"
                          placeholder="Type your reason here"
                          style={{ width: "100%", padding: "10px" }}
                          value={rejectInput}
                          onChange={handleRejectChange}
                        />
                        {/* <p>{selectedSuggestion}</p> */}
                      </div>
                      <div class="modal-footer">
                        <button
                          className="btn-small text-white"
                          style={{
                            backgroundColor: "#FF0000",
                            marginRight: "20px",
                          }}
                          onClick={() => setShowRejectModal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn-small bg-theme text-white"
                          onClick={() => {
                            handleRejectSubmit();
                          }}
                        >
                          Submit
                        </button>

                        {/* <button
                className="btn-small bg-theme text-white"
                onClick={handlePayment}
              >
                Submit
              </button> */}
                      </div>
                    </div>
                  </div>
                )}
                {showApproveModal && (
                  <div className="modal">
                    <div className="modal-header">
                      <span class="close">&times;</span>
                    </div>
                    <div class="modal-content">
                      <div className="justify-content-center align-items-center flex-column">
                        <p>Are you sure you want to approve?</p>
                      </div>
                      <div>
                        <button
                          className="btn-xs btn-success"
                          onClick={() => {
                            handleApprove(record.id);
                          }}
                          style={{ marginRight: "20px" }}
                        >
                          Yes
                        </button>
                        <button
                          className="btn-xs btn-danger"
                          onClick={() => setShowApproveModal(false)}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestActions;
