import { createBrowserRouter } from "react-router-dom/dist";
import Login from "../components/Auth/Login";
import Signup from "../components/Auth/Signup";
import Main from "../layout/Main";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../components/dashboard/Dashboard";
import AIEditor from "../components/dashboard/AIEditor";
import AdminDashboard from "../components/admin/adminDashboard";
import RequestActions from "../components/admin/requestActions";
import Landing from "../components/Home/Landing";
import Wizard from "../components/dashboard/Wizard";

import VerifyPayment from "../components/pages/VerifyPayment";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/wizard",
        element: (
            <Wizard />
        ),
      },
      {
        path: "/verifyPayment",
        element: (
          <PrivateRoute>
            <VerifyPayment />
          </PrivateRoute>
        ),
      },
      {
        path: "/ai-editor",
        element: (
          <PrivateRoute>
            <AIEditor />
          </PrivateRoute>
        ),
      },
      {
        path: "/dashboard",
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin",
        element: (
          <PrivateRoute>
            <AdminDashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "requestActions",
        element: (
          <PrivateRoute>
            <RequestActions />
          </PrivateRoute>
        ),
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
    ],
  },
]);

export default routes;
